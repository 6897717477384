<template>
  <div class="w-100">
    <div class="user__dashboard__header">
      <b-row class="align-items-end">
        <b-col lg="3" xl="3">
          <InputFilter
            label="Tìm kiếm khách hàng"
            placeholder="Nhập tên hoặc số điện thoại"
            class="form__search"
            :options="[]"
          />
        </b-col>
        <b-col lg="9" xl="9" class="d-flex justify-content-end">
          <b-overlay :show="loadingComment && !!customerInfo">
            <div v-if="customerInfo" class="coach__info">
              <div class="d-flex align-items-center">
                <avatar
                  size="40px"
                  :text="customerInfo.fullName"
                  :rounded="true"
                  :src="customerInfo.imageUrl.url"
                />
                <div class="d-flex flex-column ml-3">
                  <p class="mb-0 font-weight-bolder">
                    {{ customerInfo.fullName }}
                  </p>
                  <p class="mb-0 text-gray">
                    Gói
                    {{
                      customerInfo.ownPackage
                        ? customerInfo.ownPackage.name
                        : 'free'
                    }}
                  </p>
                </div>
              </div>
              <div class="d-flex flex-column">
                <p class="mb-0 text-gray">User code</p>
                <p class="mb-0 font-weight-bolder">{{ customerInfo.code }}</p>
              </div>
              <div class="d-flex flex-column">
                <p class="mb-0 text-gray">Năm sinh</p>
                <!-- <p class="mb-0 font-weight-bolder">
                  {{
                    customerInfo.dateOfBirth > 0
                      ? convertTimestampToDate(customerInfo.dateOfBirth, 'YYYY')
                      : null
                  }}
                </p> -->
                <p class="mb-0 font-weight-bolder">
                  {{ convertTimestampToDate(customerInfo.dateOfBirth, 'YYYY') }}
                </p>
              </div>
              <div class="d-flex flex-column">
                <p class="mb-0 text-gray">Giới tính</p>
                <p class="mb-0 font-weight-bolder">{{ customerInfo.gender }}</p>
              </div>

              <div class="d-flex flex-column">
                <p class="mb-0 text-gray">Tình trạng</p>
                <p class="mb-0 font-weight-bolder">
                  {{ getLevelOfDiabete }}
                </p>
              </div>
              <div class="d-flex flex-column">
                <p class="mb-0 text-gray">Thời gian có bệnh</p>
                <p class="mb-0 font-weight-bolder">
                  {{ getDiabeteYears }}
                </p>
              </div>
              <template
                v-if="
                  !isAssignedGroup &&
                  (isWriteAllPermission || isWritePermission)
                "
              >
                <b-button
                  v-if="isWaitingAssignGroup"
                  class="btn btn-success"
                  type="button"
                  @click="openModalAssign"
                >
                  <span class="svg-icon">
                    <inline-svg src="/media/svg/icons/Neolex/Basic/users.svg" />
                  </span>
                  Phân nhóm
                </b-button>
                <b-button
                  v-if="
                    isWaitingCoaching &&
                    (isWriteAllPermission || isWritePermission)
                  "
                  class="btn btn-success"
                  type="button"
                  @click="redirectToZoom(customerInfo.urlMeeting)"
                >
                  <span class="svg-icon">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/settings.svg"
                    />
                  </span>
                  Thực hiện
                </b-button>
                <b-button
                  v-if="
                    customerInfo.ownPackage &&
                    customerInfo.ownPackage.ownRoadmap &&
                    isActived &&
                    (isWriteAllPermission || isWritePermission)
                  "
                  class="btn btn-success"
                  type="button"
                  @click="openModalCalendar('inputInterview')"
                >
                  <span class="svg-icon">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Time/calendar.svg"
                    />
                  </span>
                  Đặt lịch đầu vào
                </b-button>
              </template>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </div>
    <div class="user__dashboard__main">
      <div class="user__dashboard__sidebar">
        <div class="user__dashboard__menu">
          <ul class="nav__list">
            <li v-for="(nav, i) in navs" :key="i" class="nav__item">
              <router-link :to="nav.to" exact-active-class="nav__active">{{
                nav.name
              }}</router-link>
            </li>
          </ul>
        </div>
        <div v-if="customerInfo" class="actions">
          <div>
            <label class="text-gray">Lịch hẹn tiếp theo</label>
            <div
              v-if="customerInfo.calendar11NextDating"
              class="font-weight-bolder"
            >
              {{
                convertTimestampToDate(
                  customerInfo.calendar11NextDating.appointmentDate,
                  'DD/MM/YYYY HH:mm',
                )
              }}
            </div>
          </div>
          <b-button
            v-if="customerInfo.calendar11NextDating"
            class="btn btn-success"
            type="button"
            @click="goTo11Appt(customerInfo)"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/video.svg" />
            </span>
            Bắt đầu
          </b-button>
          <b-button
            v-if="
              customerInfo.isBooking11 &&
              (isWriteAllPermission || isWritePermission)
            "
            class="btn btn-success"
            type="button"
            @click="openModalCalendar('coach11')"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/video.svg" />
            </span>
            Đặt lịch
          </b-button>
          <b-button
            class="btn btn-success"
            type="button"
            @click="redirectToZalo(customerInfo.zaloUrl)"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Chatting/comment.svg" />
            </span>
            Nhắn tin
          </b-button>
        </div>
        <div class="user__dashboard__comment">
          <b-overlay :show="loadingComment">
            <h6>Nhận xét</h6>
            <template v-if="lastComment">
              <div class="infomation py-2">
                <avatar
                  size="40px"
                  :text="lastComment.creator"
                  rounded
                  :src="lastComment.reviewerUrl && lastComment.reviewerUrl.url"
                />
                <div>
                  <div class="font-weight-bolder">
                    {{ lastComment.creator }}
                  </div>
                  <div class="text-gray">
                    {{ convertTimestampToDate(lastComment.updateDateTime) }}
                  </div>
                </div>
              </div>
              <div class="py-2">
                <div class="font-weight-bolder text-success">Buổi coach:</div>
                <div>{{ getCoachType(lastComment.type) }}</div>
              </div>
              <div class="py-2">
                <div class="font-weight-bolder text-success">Tiến độ học:</div>
                <div
                  class="study__progress"
                  :class="getClassByProgressType(lastComment.progressType)"
                >
                  {{ getStudyProgress(lastComment.progressType) }}
                </div>
              </div>
              <div class="py-2">
                <div class="font-weight-bolder text-success">Ưu tiên:</div>
                <div
                  class="support"
                  :class="getClassBySupportType(lastComment.supportTypes)"
                >
                  {{ getSupportTypes(lastComment.supportTypes) }}
                </div>
              </div>
              <div
                v-if="lastComment.comment || lastComment.finalResult"
                class="py-2"
              >
                <div class="font-weight-bolder text-success">
                  Nhận xét của coach:
                </div>
                <div
                  v-html="lastComment.comment || lastComment.finalResult"
                  id="comment"
                ></div>
              </div>
              <div v-if="lastComment.plan" class="py-2">
                <div class="font-weight-bolder text-success">
                  Kế hoạch tiếp theo:
                </div>
                <div v-html="lastComment.plan"></div>
              </div>
            </template>
            <div class="py-2">
              <b-button
                class="btn btn-success w-100 mb-4"
                type="button"
                @click="openModalComments"
              >
                Xem tất cả
              </b-button>
              <b-button
                v-if="
                  isPartWritePermission ||
                  isWriteAllPermission ||
                  isWritePermission
                "
                class="btn btn-success w-100"
                type="button"
                @click="addComment"
              >
                Thêm mới
              </b-button>
            </div>
          </b-overlay>
        </div>
      </div>
      <div class="user__dashboard__content container-fluid">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
    <ModalAssign
      v-if="showModalAssign"
      :show="showModalAssign"
      :customer="customerInfo"
      @reset="resetModalAssign"
    />
    <ModalCommentList @reset="resetModalComments" />

    <ModalCalendar
      :type-form="typeForm"
      :customer="customerInfo"
      :isInputInterview="isInputInterview"
      :isCoach11="isCoach11"
      :prop-patient="customerInfo"
      @save="createSchedule"
      @hide="resetModalCalendar"
    />
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  SET_STATE_MODAL_COMMENT,
  SET_CUSTOMER_INFO,
  GET_LAST_COMMENT,
} from '@/core/services/store/userDashboard.module';
import {
  ACTIVE_ACCOUNT_STATUS,
  CALENDAR_TYPES,
  MODAL_TYPE,
  STUDY_PROGRESS_TYPES,
  SUPORT_TYPES,
} from '../../../core/plugins/constants';
import {
  CREATE_CALENDER,
  SET_LOADING,
} from '@/core/services/store/calender.module';
const {
  mapState: userDashboardState,
  mapMutations: userDashboardMutations,
  mapActions: userDashboardActions,
} = createNamespacedHelpers('userDashboard');
const {
  mapActions: calendarActions,
  mapMutations: calendarMutations,
} = createNamespacedHelpers('calender');
export default {
  name: 'UserDashboardLayout',
  components: {
    ModalAssign: () => import('../active_account/components/ModalAssign.vue'),
    ModalCommentList: () => import('./components/ModalCommentList.vue'),
    InputFilter: () => import('./components/InputFilter.vue'),
    ModalCalendar: () =>
      import('@/view/pages/calendar/CalendarScheduleForm.vue'),
  },
  data() {
    return {
      navs: [
        {
          name: 'Tổng hợp',
          to: 'over_view',
        },
        {
          name: 'Chỉ số sinh học',
          to: 'biological',
        },
        {
          name: 'Lịch trình',
          to: 'schedule',
        },
        {
          name: 'Bài học',
          to: 'lessons',
        },
        {
          name: 'Kết quả khảo sát',
          to: 'survey_result',
        },
        {
          name: 'Báo cáo học viên',
          to: 'student_report',
        },
      ],
      patients: [],
      showModalAssign: false,
      showModalCalendar: false,
      customerInfo: null,
      typeForm: MODAL_TYPE.CREATE,
      isCoach11: false,
      isInputInterview: false,
    };
  },
  computed: {
    ...userDashboardState([
      'showModalComment',
      'lastComment',
      'loadingComment',
    ]),
    patientId() {
      return this.$route.params.id;
    },
    isActived() {
      return (
        this.customerInfo?.packageAccountStatus ===
        ACTIVE_ACCOUNT_STATUS.ACTIVED
      );
    },
    isWaitingCoaching() {
      return (
        this.customerInfo?.packageAccountStatus ===
        ACTIVE_ACCOUNT_STATUS.WAITING_COACHING
      );
    },
    isWaitingAssignGroup() {
      return (
        this.customerInfo?.packageAccountStatus ===
        ACTIVE_ACCOUNT_STATUS.WAITING_ASSIGN_GROUP
      );
    },
    isAssignedGroup() {
      return (
        this.customerInfo?.packageAccountStatus ===
        ACTIVE_ACCOUNT_STATUS.ASSIGNED_GROUP
      );
    },
    getLevelOfDiabete() {
      if (!this.customerInfo) return;

      const diabete = this.customerInfo.levelOfDiabetesRuleList?.find(
        (el) => el.selected,
      );
      return diabete?.text || this.customerInfo.diabetes.name;
    },
    getDiabeteYears() {
      return `${
        this.customerInfo.totalYears < 1 ||
        (this.customerInfo.totalYears === 1 &&
          this.customerInfo.totalMonths === 0)
          ? '< 1'
          : this.customerInfo.totalYears
      } năm`;
    },
    isPartWritePermission() {
      return this.$route.meta.isPartWritePermission;
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    async patientId() {
      await Promise.all([this.getCustomerProfile(), this.getLastComment()]);
    },
  },
  async created() {
    await Promise.all([this.getCustomerProfile(), this.getLastComment()]);
  },
  methods: {
    ...userDashboardMutations({ SET_STATE_MODAL_COMMENT, SET_CUSTOMER_INFO }),
    ...userDashboardActions({ GET_LAST_COMMENT }),
    ...calendarActions({ CREATE_CALENDER }),
    ...calendarMutations({ SET_LOADING }),
    openModalCalendar(type) {
      if (type === 'coach11') {
        this.isCoach11 = true;
      } else {
        this.isInputInterview = true;
      }
      this.$bvModal.show('calendar-schedule-add-modal');
    },
    resetModalCalendar() {
      this.$bvModal.hide('calendar-schedule-add-modal');
      this.isCoach11 = false;
      this.isInputInterview = false;
    },
    openModalAssign() {
      this.showModalAssign = true;
    },
    resetModalAssign() {
      this.showModalAssign = false;
      this.getCustomerProfile();
    },
    openModalComments() {
      this.$bvModal.show('modal-comment-list');
    },
    resetModalComments() {},
    redirectToZoom(url) {
      if (!url) return;

      window.open(url);
    },
    addComment() {
      this.$bvModal.show('modal-comment');
    },
    resetModalComment() {
      this.SET_STATE_MODAL_COMMENT(false);
    },
    async getCustomerProfile() {
      try {
        const { meta, data } = await this.$api.get('/UserDashboard/Patient', {
          params: {
            patientId: this.patientId,
          },
        });
        if (!meta.success) return;
        this.customerInfo = data.items[0];
        this.SET_CUSTOMER_INFO(this.customerInfo);
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    async getLastComment() {
      await this.GET_LAST_COMMENT(this.patientId);
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    async createSchedule({ data }) {
      try {
        this.SET_LOADING(true);
        const res = await this.CREATE_CALENDER(data);

        if (!res.data) {
          return this.showToastrMessage(res.error.message, 'error');
        }

        await this.$api.post('/PackageAccountTransaction/BookCalender', {
          bookingId: res.data.id,
          id: this.customerInfo.packageAccountTransactionId,
        });
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Tạo mới lịch hẹn thành công',
        });
        await this.getCustomerProfile();
        this.resetModalCalendar();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.SET_LOADING(false);
      }
    },
    redirectToZalo(url) {
      window.open(url);
    },
    getCoachType(type) {
      switch (type) {
        case CALENDAR_TYPES.OUTPUT_RATING:
          return 'Đánh giá đầu ra';
        case CALENDAR_TYPES.INPUT_RATING:
          return 'Đánh giá đầu vào';
        case CALENDAR_TYPES.COACH_11:
          return 'Tư vấn cá nhân';

        default:
          return 'Tư vấn nhóm';
      }
    },
    getStudyProgress(type) {
      switch (type) {
        case STUDY_PROGRESS_TYPES.NEED_IMPROVE:
          return 'Cần cải thiện';
        case STUDY_PROGRESS_TYPES.IMPROVING:
          return 'Có tiến bộ';
        case STUDY_PROGRESS_TYPES.RIGHT_WAY:
          return 'Đúng lộ trình';
        case STUDY_PROGRESS_TYPES.VERY_GOOD:
          return 'Rất tốt';
        case STUDY_PROGRESS_TYPES.GOOD:
          return 'Tốt';

        default:
          return 'Ngưng học';
      }
    },
    getClassByProgressType(type) {
      switch (type) {
        case STUDY_PROGRESS_TYPES.NEED_IMPROVE:
          return 'need__improve';
        case STUDY_PROGRESS_TYPES.IMPROVING:
          return 'improving';
        case STUDY_PROGRESS_TYPES.RIGHT_WAY:
          return 'right__way';
        case STUDY_PROGRESS_TYPES.VERY_GOOD:
          return 'very__good';
        case STUDY_PROGRESS_TYPES.GOOD:
          return 'good';

        default:
          return 'pending';
      }
    },
    getSupportTypes(type) {
      switch (type) {
        case SUPORT_TYPES.PREFER:
          return 'Ưu tiên';

        default:
          return 'Bình thường';
      }
    },
    getClassBySupportType(type) {
      switch (type) {
        case SUPORT_TYPES.PREFER:
          return 'prefer';

        default:
          return 'normal';
      }
    },
    goTo11Appt(item) {
      if (item?.calendar11NextDating?.meetingLink) {
        window.open(item.calendar11NextDating.meetingLink, '_blank');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.coach__info {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  column-gap: 28px;

  @media only screen and (max-width: 1366px) {
    column-gap: 16px;
  }
}

.user__dashboard {
  &__header {
    padding: 16px 24px;
    background: #fff;
    border-top: 1px solid #ebedf3;
  }

  &__main {
    display: grid;
    grid-template-columns: 196px 1fr;
    padding: 24px;
    column-gap: 24px;

    @media only screen and (max-width: 1366px) {
      column-gap: 16px;
      padding: 16px;
      grid-template-columns: 176px 1fr;
    }
  }

  &__sidebar {
    display: grid;
    grid-template-rows: repeat(3, min-content);
    row-gap: 24px;

    @media only screen and (max-width: 1366px) {
      row-gap: 16px;
    }
  }

  &__menu,
  &__comment,
  &__content {
    background: #fff;
    border-radius: 6px;
    padding: 16px;
  }

  &__content {
    overflow-x: hidden;
  }
}

.nav {
  &__list {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    row-gap: 20px;
    list-style: none;
  }

  &__item {
    a {
      color: #000;

      &.nav__active {
        color: #008479;
        font-weight: 600;
      }
    }
  }
}

.actions {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  row-gap: 12px;

  background: #fff;
  border-radius: 6px;
  padding: 16px;
}

.text-gray {
  color: #888c9f;
  font-size: 12px;
  line-height: 16px;
}

.infomation {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 16px;
}

.study__progress {
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 6px 12px;
  width: 100px;
  margin-top: 8px;

  &.need__improve {
    background-color: #e53935;
  }
  &.improving {
    background-color: #f58220;
  }
  &.right__way {
    background-color: #01645a;
  }
  &.very__good {
    background-color: #21a567;
  }
  &.good {
    background-color: #50c087;
  }
  &.pending {
    background-color: #454649;
  }
}
.support {
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 6px 12px;
  width: 100px;
  margin-top: 8px;

  &.prefer {
    background-color: #f58220;
  }
  &.normal {
    background-color: #01645a;
  }
}
</style>

<style lang="scss">
.form__search {
  margin-bottom: 0;
  min-width: 220px;
}
</style>
